/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/main.scss'

export const onInitialClientRender = () => {
  const hash = window.location.hash
  const el = document.getElementById(hash.substring(1))
  if (hash && el) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' })
    }, 750)
  } else {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
}
