import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import variables from '@styles/variables.module.scss'
import { useBreakpoint } from '@utils/hooks'
import style from './styles.module.scss'
const cx = classNames.bind(style)

const gridSetup = {
  small: {
    cols: parseInt(variables.colsSmall),
    colClass: 'col-s',
    gridClass: 'mobile-grid',
  },
  medium: {
    cols: parseInt(variables.colsMedium),
    colClass: 'col-m',
    gridClass: 'tablet-grid',
  },
  large: {
    cols: parseInt(variables.colsLarge),
    colClass: 'col-l',
    gridClass: 'grid',
  },
}

const GridHelper = ({ cols, class: colClass, gridClass, withPadding }) => {
  const breakpoint = useBreakpoint()

  const gridSetupDefault = gridSetup[breakpoint]
  return (
    <div className={cx('helper-container')}>
      <div className={cx('container', gridClass || gridSetupDefault.gridClass, { 'container-padding': withPadding })}>
        {Array(cols || gridSetupDefault.cols)
          .fill(null)
          .map((_, index) => (
            <div
              className={cx(colClass || gridSetupDefault.colClass, `${colClass || gridSetupDefault.colClass}-1`)}
              key={index}
            >
              <div className={cx('colHelper')} />
            </div>
          ))}
      </div>
    </div>
  )
}

GridHelper.propTypes = {
  cols: PropTypes.object,
  class: PropTypes.string,
  gridClass: PropTypes.string,
  withPadding: PropTypes.bool,
}

export default GridHelper
