import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import GridHelper from '@helpers/GridHelper'

const GridHelperLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          env
        }
      }
    }
  `)

  if (
    data &&
    data.site &&
    data.site.siteMetadata &&
    data.site.siteMetadata &&
    data.site.siteMetadata.env === 'production'
  ) {
    return children
  }

  const [showGrid, toggleGrid] = useState(false)
  const hasWindow = typeof window !== 'undefined'
  const handleKeyDown = e => {
    if (e.metaKey && e.keyCode === 190) {
      toggleGrid(state => !state)
    }
  }

  useEffect(() => {
    hasWindow && window.addEventListener('keydown', handleKeyDown)
    return () => {
      hasWindow && window.removeEventListener('keydown', handleKeyDown)
    }
  })
  return (
    <>
      {showGrid && <GridHelper withPadding />}
      {children}
    </>
  )
}

GridHelperLayout.propTypes = {
  children: PropTypes.node,
}

export default GridHelperLayout
