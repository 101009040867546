import { useEffect, useState } from 'react'
import { bpLarge, bpMedium, bpSmall } from '@styles/variables.module.scss'

const breakpoints = [bpSmall, bpMedium, bpLarge].map(item => Number(item.replace('px', '')))

const getBreakpoint = width => {
  if (width < breakpoints[1]) {
    return 'small'
  } else if (width >= breakpoints[1] && width < breakpoints[2]) {
    return 'medium'
  } else {
    return 'large'
  }
}

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(typeof window !== 'undefined' && getBreakpoint(window.innerWidth))
  const handleResize = () => {
    setBreakpoint(getBreakpoint(window.innerWidth))
  }

  useEffect(() => {
    window && window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return breakpoint
}

export const useClient = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  })
  return isClient
}
